import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'


const SuccessPage = props => (
  <Layout>
    <SEO title='Erfolg' />
    <div className='max-w-6xl mx-auto text-center py-12'>
      <div className='text-6xl font-medium text-brand-blue leading-none my-3 uppercase'>Erfolg!</div>
      <p>Danke für die Nachricht</p>
      <p>Gehen Sie zur <Link to='/' className='font-semibold text-brand-blue transform transition duration-150 ease-in-out hover:text-brand-orange'>Startseite</Link></p>
    </div>
  </Layout>
)

export default SuccessPage
